<template>
  <b-modal
    id="permission-create-modal"
    ref="permission-create-modal"
    title="Add Permission"
    centered
    hide-footer
    no-close-on-backdrop
    @shown="doLoadData"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer">
      <b-form @submit.stop.prevent>
        <b-row>
          <!-- !! Name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <HeroInputText
                id="name"
                v-model="permission.name"
                label="Name"
                column="4"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Subject -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="subject"
              rules="required"
            >
              <HeroVueSelectText
                id="subject"
                v-model="permission.subject"
                label="Subject"
                column="4"
                :required="true"
                :clearable="false"
                :options="$store.getters['Permissions/getSubjectOptions']"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
                @input="updateAbilityOptions"
              />
            </validation-provider>
          </b-col>

          <!-- !! Ability -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="ability"
              rules="required"
            >
              <HeroVueSelectText
                id="ability"
                v-model="permission.ability"
                label="Ability"
                column="4"
                :required="true"
                :clearable="false"
                :options="$store.getters['Permissions/getAbilityOptions']"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Description -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="description"
              rules=""
            >
              <HeroTextarea
                id="description"
                v-model="permission.description"
                label="Description"
                column="4"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction type="button" variant="primary" @click="validationForm">
              Save
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" sm="3">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import HeroVueSelectText from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    HeroInputText,
    HeroTextarea,
    HeroVueSelectText,
    HeroButtonAction,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      // Validation
      required,

      original: {
        name: '',
        subject: '',
        ability: '',
        description: '',
      },
      permission: {
        name: '',
        subject: '',
        ability: '',
        description: '',
      },
    }
  },
  methods: {
    doCloseModal() {
      this.$refs['permission-create-modal'].hide()
    },

    async doLoadData() {
      this.updateAbilityOptions()
    },

    async validationForm() {
      let alertResult
      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        this.showOverlay = true

        const body = this.permission

        try {
          const response = await axiosInstance.post('permissions', body)

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          if (alertResult.value) {
            this.permission = { ...this.original }
            this.$refs['permission-create-modal'].hide()
            this.$emit('success')
          }
        } catch (error) {
          this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        } finally {
          this.showOverlay = false
        }
      }
    },

    updateAbilityOptions() {
      this.permission.ability = ''
      this.$store.dispatch('Permissions/fetchAbilities', this.permission.subject)
    },
  },
}
</script>
